import {Component, OnInit} from '@angular/core';
import { ApmService } from '@core/services/logging/apm.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor ( 
        private apmService: ApmService 
    ) {}

    ngOnInit(): void {
        this.loadDatadog();
    }

    loadDatadog = () => {
        this.apmService.loadDatadogScript();
    }

}
