import {Api} from '@environment/api/api.qa';
import {App} from '@environment/app/app.local';
import {Common} from '@environment/common/common.local';

export const environment: any = {
    name: 'qa',
    gaEnv: 'qa',
    gaCommerce: 'BFCL_WEB',
    analitycsUA: 'UA-20384930-16',
    headers: {
        'accept-version': 'v1',
        'x-flow-country': 'CL',
        'x-flow-commerce': 'CMR',
        'x-flow-channel': 'WEB',
        'x-flow-business': 'CMR_CL',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    useAuthenticate: true,
    production: false,
    api: Api.apis,
    common: Common.commons,
    apiUrl: `${process.env.API_URL || window['env']['apiUrl'] || 'https://pagorapido-qa.bancofalabella.cl/web/cmrcl/expresspay/api'}`,
    baseUrl: 'https://pagorapido-qa.bancofalabella.cl',
    blobStorageUrl: 'https://static-qa.fif.tech/omni-upgrade-migration-config/bfcl/config-pago-eecc-expresspay-bfcl.json',
    npsURL: 'https://apollo-public.loyal.ink/survey/YmFuY28tZmFsYWJlbGxhLWNoaWxlL3Rlc3Q=.js',
    app: App.confs,
    cipher: {
        useInRequest: true,
        useInResponse: true,
        storageKey: 'f8220093c8a07faa9d6eec88827cf0c9',
        publicKey:
          '-----BEGIN PUBLIC KEY-----\n' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr/uJqitbcJG/9jLH7lDu\n' +
          'X/jg5Q2VxDh4iwBtQvPigZiZRuChuowU9XmW+XS6J8N1lXcpe9vhErBFKX/9ELAa\n' +
          'r3PnIYVnjtVqLxro2f0eqocdWw1AN2lx01SqxiqmdHEZCsj9mEQ6sYNcNdeH6G/9\n' +
          'GNk/3Y7c0H90Gi/+vWYN1sVr+LJR5CU/emdO1W2FaQ8T2+GWhyNVOkDZEx4dTsDE\n' +
          'cX0PmGUwxPv+2gZb99fNxhwKV4Vq5Mt9ACAl3vrhUH33kZPuw3miJHKXvlw+9kuU\n' +
          'uOFJFOga/D9FMfCrYcCqlBuzf2T6w0Q19Ik+uyo3zLn3YFN1ufBfeAV51Z0kKBiT\n' +
          'zwIDAQAB\n' +
          '-----END PUBLIC KEY-----',
    },
    CAPTCHA_ENABLE: true,
    CAPTCHA_KEY: `${process.env.SITE_KEY || window['env']['siteKey'] || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}`, // 6LcL9OMUAAAAANGNe0p-LqDovPfxKMlLeiCSVjra
    EXPRESS_PAY_CREDITS: {
        URL: 'https://pagorapidocreditos-qa.bancofalabella.cl/'
    },
    PUBLIC_SITE_URL: 'https://www-qa.bancofalabella.cl',
    performance: {
        datadog: {
            clientToken: 'pub1b208d599d6a6ba9990d426693f7c753',
            applicationId: '37e47952-61f5-45dd-8a0a-b454ba141d6a',
            env: 'qa',
            version: `${process.env.NP_RELEASE_SEMVER || window['env']['releaseSemver'] || '1.0.0'}`
        }
    },
    COMMON_ENCRYPT: {
        URL: 'https://pagorapido-qa.bancofalabella.cl/expresspay/common/api/v1/crypt-help'
    }
};
