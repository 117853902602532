import { ErrorHandler } from "@angular/core";

declare var ineum;

export class ApmErrorHandler implements ErrorHandler {
    handleError(error) {
        //ineum('reportError', error);
        console.error(error);
    }
}
