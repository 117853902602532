import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { ScriptLoaderService } from './script-loader.service';
import { sha256 } from 'hash.js';
import { LoggingService } from './logging.interface';
import { LogState } from './logging.enum';

declare global {
    interface Window {
        DD_RUM: any;
    }
}

@Injectable({
  providedIn: 'root'
})
export class ApmService implements LoggingService {

  constructor(
    private scriptLoaderService: ScriptLoaderService,
  ) { }

  public createLog(category: string, endpoint: string, state: LogState, error?: any) {
    this.markEvent(category, endpoint, state, error);
  }

  loadDatadogScript = (): Promise<any> => {
      const clientToken = environment.performance.datadog.clientToken;
      const applicationId = environment.performance.datadog.applicationId;
      const serviceName = 'banco-cl-pagorapidocmr-ui';
      const env = environment.performance.datadog.env;
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('id', 'datadog-script');
      scriptTag.setAttribute('type', 'text/javascript');
      scriptTag.text = `
    (function(h,o,u,n,d) {
      h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
      d=o.createElement(u);d.async=1;d.src=n
      n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
      DD_RUM.onReady(function() {
        DD_RUM.init({
          clientToken: "${clientToken}",
          applicationId:  "${applicationId}",
          site: 'datadoghq.com',
          env: "${env}",
          service: "${serviceName}",
          version: '1.0.0',
          sampleRate: 100,
          premiumSampleRate: 0,
          defaultPrivacyLevel: 'mask-user-input'
        });
      DD_RUM.startSessionReplayRecording();
    })`;
      document.body.appendChild(scriptTag);
      return Promise.resolve();
  }

  markEvent = (category: string, endpoint: string, state: LogState, error?: any) => {
    const formatted_endpoint = endpoint.replace(environment.apiUrl, '').replace(/\//g, '_');

    const params = {
      meta: {}
    };

    if (error) {
      state = LogState.ERROR;
      params['meta']['error'] = error;
    }

    const event = `${category}_${formatted_endpoint}_${state}`.toLowerCase();

    // Datadog
      const metaData = {
          value: event,
          ...params
      };
      if (!environment.production) {
          console.log('<<<<<< Datadog: ', event, metaData);
      }
      if (window.DD_RUM !== undefined) {
          window.DD_RUM.onReady(function() {
              window.DD_RUM.addAction(event, metaData);
          });
      }
  }

  hashIdentifier = (identifier: string): string => this.hash(this.cleanIdentifier(identifier));

  hash = (string: string): string => {
    return sha256().update(string).digest('hex');
  }

  cleanIdentifier = (id: string): string => id.replace(/[^0-9kK]+/g, '').toUpperCase();
}
