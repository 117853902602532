import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {

    @Input()
    public content: string;

    @Input()
    public title: string;

    @Input()
    public formData: any;

    @Output()
    public confirmData: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public hidden = true;

    @Input()
    public primaryButton = "Aceptar";

    @Input()
    public secondaryButton = "Cancelar";

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.hidden = !this.hidden;
    }

    dimiss(){
        this.toggle();
        this.confirmData.emit(false);
    }

    confirm() {
        this.toggle();
        this.confirmData.emit(true);
    }
}
