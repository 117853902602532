// tslint:disable:max-line-length
export class Api {
  public static apis: any = {
    service_login: '/onboarding/acquisition/login/validate/',
    service_refresh_token: 'http://localhost:3001/onboarding/acquisition/login/refresh/',
    service_revoke_token: '/onboarding/acquisition/login/revoke/',
    service_personal_data: '/onboarding/acquisition/application/verify/',
    service_intention: '/onboarding/acquisition/application/intention/',
    service_evaluate_person: '/onboarding/assessment/evaluate/verify/first',
    service_evaluate_logger: '/onboarding/assessment/evaluate/logger',
    service_second_evaluate_person: '/onboarding/assessment/evaluate/verify/second',
    service_more_job_info: '/onboarding/assessment/evaluate/verify/more-job-info/',
    service_application_create: '/onboarding/assessment/application/create',
    service_content_ful: '/onboarding/cross/contentful/v1/products',
    service_authenticate: '/onboarding/cross/test/authentication-manager/assessment/type',
    service_external_authenticate_generate: '/onboarding/cross/test/authentication-manager/assessment/external/generate',
    service_external_authenticate_validate: '/onboarding/cross/test/authentication-manager/assessment/external/validate',
    service_verify_first_factor: '/onboarding/cross/test/authentication-ingress/verify/assessment/first-factor/validation',
    service_verify_second_factor_generation: '/onboarding/cross/test/authentication-ingress/verify/assessment/second-factor/otp/generation',
    service_verify_second_factor_validation: '/onboarding/cross/test/authentication-ingress/verify/assessment/second-factor/otp/validation',
    service_front_log: '/onboarding/general/front-log/v1/',
    service_linkage_job_person_address: 'http://localhost:5001/onboarding/linkage/address/v1/job-person-address',
    service_linkage_occupational_data: 'http://localhost:5001/onboarding/linkage/client/v1/job-info', // job info
    service_linkage_document_upload: 'http://localhost:5001/onboarding/linkage/document/v1/upload-id',
    service_linkage_document_save: 'http://localhost:5001/onboarding/linkage/document/v1/save',
    service_linkage_personal_data: 'http://localhost:5001/onboarding/linkage/client/v1/personal-data',
    service_linkage_referee_data: 'http://localhost:5001/onboarding/linkage/client/v1/referee-data',
    service_linkage_pac_info: 'http://localhost:5001/onboarding/linkage/client/v1/pac-info',
    service_linkage_pep_fatca: 'http://localhost:5001/onboarding/linkage/client/v1/pep-fatca-info',
    service_linkage_payment_date: 'http://localhost:5001/onboarding/linkage/client/v1/payment-date',
    service_client_create: 'http://localhost:5001/onboarding/linkage/client/v1/create-client',
  };
}
