// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {environment as local} from '@environment/environment.local';
import {environment as development} from '@environment/environment.dev';
import {environment as qa} from '@environment/environment.qa';
import {environment as prod} from '@environment/environment.prod';

const selectEnvironmentFile = () => {
    switch (process.env.NODE_ENV || window['env']['environment']) {
        case 'development':
        case 'test':
            return development;
        case 'qa':
            return qa;
        case 'prod':
            return prod;
    }
    return local;
};

export const environment: any = selectEnvironmentFile();

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
