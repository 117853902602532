import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environment/environment';
import { appInjector } from './instance-prevent';

if (environment.production) {
    enableProdMode();
}

environment.NODE_ENV = process.env.NODE_ENV;
environment.NODE_ENV_APP = process.env.NODE_ENV_APP;

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((appRef) => {
            appInjector(appRef.injector);
        })
        .catch((err) => console.log(err));
});
