import {Api} from '@environment/api/api.local';
import {App} from '@environment/app/app.local';
import {Common} from '@environment/common/common.local';

export const environment: any = {
    name: 'test',
    gaEnv: 'test',
    gaCommerce: 'BFCL_WEB',
    analitycsUA: 'UA-20384930-16',
    analitycsGTM: 'GTM-PP82XLB',
    headers: {
        'accept-version': 'v1',
        'x-flow-country': 'CL',
        'x-flow-commerce': 'CMR',
        'x-flow-channel': 'WEB',
        'x-flow-business': 'CMR_CL',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-forwarded-for': '127.0.0.1,127.0.0.1'
    },
    useAuthenticate: true,
    production: false,
    api: Api.apis,
    common: Common.commons,
    apiUrl: 'http://localhost:3701/web/cmrcl/expresspay/api',
    baseUrl: 'http://localhost:3701',
    blobStorageUrl: 'https://static-test.fif.tech/omni-upgrade-migration-config/bfcl/config-pago-eecc-expresspay-bfcl.json',
    npsURL: 'https://apollo-public.loyal.ink/survey/YmFuY28tZmFsYWJlbGxhLWNoaWxlL3Rlc3Q=.js',
    app: App.confs,
    cipher: {
        useInRequest: false,
        useInResponse: false,
        storageKey: 'f8220093c8a07faa9d6eec88827cf0c9',
        publicKey:
          '-----BEGIN PUBLIC KEY-----\n' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0vn/B66UiMc13m7Gu3+7\n' +
          'mRzkLzvzf0WMSsReSqdtZIGewcYp99KM5maW1fgIY1phxS++8AoVc34eW7T+E2xM\n' +
          't2xX2HhIC0NF+DLNvrHEyrTc+tCxt0cp8K7rvczqWWEofgrednbNnPG7jj99lraM\n' +
          'XVCpPuchRY9NRFzitKegKI3wNR1DE6C7k3QEsl2Q3bWcavyWl/j51DsBCCppuVEC\n' +
          'A5IZxjRU3gect7jKg4omk5hGvX2Gv7FOc7BC2D0yzXitLklS6BYsRm5sPMeZPnRS\n' +
          'ymi78CgCPA68AYrShDeIdzeXQTdUajjs2j1AXoU6famj/WJb9NMwHpkG8yzjL6GU\n' +
          'AQIDAQAB\n' +
          '-----END PUBLIC KEY-----',
    },
    CAPTCHA_ENABLE: true,
    CAPTCHA_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', //'6LfQt-UUAAAAAO0Qbtp83qpvXIKQTb0GaHSbLl8R',
    EXPRESS_PAY_CREDITS: {
        URL: 'http://localhost:4300'
    },
    PUBLIC_SITE_URL: 'https://www-test.bancofalabella.cl',
    performance: {
        datadog: {
            clientToken: 'pub1b208d599d6a6ba9990d426693f7c753',
            applicationId: '37e47952-61f5-45dd-8a0a-b454ba141d6a',
            env: 'local',
            version: '1.0.0'
        }
    },
    COMMON_ENCRYPT: {
        URL: 'http://localhost:3701/common/api/v1/crypt-help'
    }
};
