import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StorageHandlerModule} from 'cob-infrastructure';
import {HeaderComponent} from './components/header/header.component';
import {LoginAvatarComponent} from './components/login-avatar/login-avatar.component';
import {ModalInfoComponent} from './components/modal-info/modal-info.component';
import {ModalDialogComponent} from './components/modal-dialog/modal-dialog.component';
import {ModalLoaderComponent} from './components/modal-loader/modal-loader.component';
import {ProgressCircleComponent} from './components/progress-circle/progress-circle.component';

@NgModule({
    imports: [CommonModule, StorageHandlerModule],
    declarations: [
        HeaderComponent,
        ModalLoaderComponent,
        LoginAvatarComponent,
        ModalInfoComponent,
        ModalDialogComponent,
        ProgressCircleComponent
    ],
    exports: [
        HeaderComponent,
        ModalLoaderComponent,
        LoginAvatarComponent,
        ModalInfoComponent,
        ModalDialogComponent,
        ProgressCircleComponent
    ],
    providers: [],
})
export class SharedModule {
}
