export enum RoutesPaths {
    ADDITIONAL_DATA = 'additional-data',
    FIRTS_EVALUATE = 'evaluate/checkingEvaluate',
    PRODUCT_CROSSING = 'product-crossing',
    PRODUCT_CROSSING_WITHOUT_OFFER = 'product-crossing-without-offer',
    EMPLOYMENT_INFO = 'employment-info',
    FINISHED_PROCESS = 'finished-process',
    GENERIC_ERROR = 'evaluate/error',
    LANDING = '',
    FAILED_PROCESS = 'failed-process',
    OFFER = 'evaluate/withOffer',
    SALES_AGENT_LOGIN = 'sales-agent-login',
    UNAUTHORIZED = 'unauthorized',
    UNAUTHORIZED_ERROR = 'unauthorized-error',
    WITHOUT_OFFER = 'evaluate/withoutOffer',
    AUTHENTICATION = 'evaluate/authentication',
    PRODUCTS_SELECTION = 'products-selection',
    SECOND_EVALUATE = 'evaluate/checkingEvaluateFull',
    BIRTHPLACE_LOCAL = 'birthplace-local',
    BIRTHPLACE_FOREIGNER = 'birthplace-foreigner',
    JOB_INFO = 'job-info',
    PEP_FATCA = 'pep-fatca',
    REFEREE_DATA = 'referee-data',
    PAYMENT_DATE = 'payment-date',
    PAC_PURPOSE = 'pac-purpose',
    PRODUCT_READY = 'product-ready',
    CLIENT_CREATE = 'client-create',
    UPLOAD_ID = 'upload-id'
}
