export enum SessionKeys {
    ADDITIONAL_DATA = 'additional-data',
    CLIENTS_INTENTION = 'clientsIntention',
    EMPLOYMENT_INFO = 'employment-info',
    EXTRA_FIELDS = 'extraField',
    FLOW_TYPE = 'flowType',
    USERNAME = 'username',
    SECOND_EVALUATE = 'second',
    CONTENT = 'content',
    PERSONAL_DATA = 'personal-data',
    PEP_FATCA = 'pep-fatca',
    REFEREE_DATA = ' referee-data',
    IS_TC = 'is-tc',
    IS_PAC = 'is-pac',
    REQUESTED_PRODUCTS = 'requested-products',
    IS_FRAUD = 'is-fraud',
    NEXT_PAGE_AFTER_UPLOAD_ID = 'next-page-after-upload-id',
    SALARY = 'salary',
    FLOWSESSIONID = 'flowSessionId'
}
