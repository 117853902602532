import { HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import CryptoService from "@assets/crypto.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SecureServiceCallInterceptor implements HttpInterceptor {
    headers = environment.headers;
    cryptoService = new CryptoService();

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const key = this.cryptoService.generateRandomKey(64);
        if (this.shouldEncryptAndDecrypt(req)) {
            // console.log('==> request', req);
            req = this.generateEncryptedRequest(req, key)
            // console.log('===> encrypted request', req)
        }

        return next.handle(req).pipe(
            map(response => {
                if (response.type === HttpEventType.Response && this.shouldEncryptAndDecrypt(response)) {
                    // console.log('<=== encrypted response', response);
                    response = this.generateDecryptedResponse(response, key);
                    // console.log('<=== response', response);
                }

                return response;
            })
        );
    }

    private generateEncryptedRequest(req: HttpRequest<any>, key) {
        const encryptedBody = {};

        for (let param of Object.keys(req.body)) {
            encryptedBody[param] = this.cryptoService.aesEncrypt(req.body[param], key);
        }

        const headers = {
            ...environment.headers,
            tknc: this.cryptoService.encryptWithPublicKey(key)
        };

        req = req.clone({
            headers: new HttpHeaders(headers),
            body: encryptedBody,
        });

        return req;
    }

    private shouldEncryptAndDecrypt(req) {
        return (
            (req.url.indexOf(environment.apiUrl) >= 0)
        );
    }

    private generateDecryptedResponse(response: HttpResponse<any>, key) {
        const decryptedBody = this.cryptoService.aesDecrypt(response.body, key);

        response = response.clone({
            body: decryptedBody,
        });

        return response;
    }
}
