// tslint:disable: max-line-length
export class Api {
  public static apis: any = {
    service_authenticate:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/type',
    service_login:
      'https://banco-co-onboarding-test.fif.tech/onboarding/acquisition/login/validate/',
    service_refresh_token:
      'https://banco-co-onboarding-test.fif.tech/onboarding/acquisition/login/refresh/',
    service_revoke_token:
      'https://banco-co-onboarding-test.fif.tech/onboarding/acquisition/login/revoke/',
    service_personal_data:
      'https://banco-co-onboarding-test.fif.tech/onboarding/acquisition/application/verify/',
    service_intention:
      'https://banco-co-onboarding-test.fif.tech/onboarding/acquisition/application/intention/',
    service_evaluate_person:
      'https://banco-co-onboarding-test.fif.tech/onboarding/assessment/evaluate/verify/first',
    service_application_create:
      'https://banco-co-onboarding-test.fif.tech/onboarding/assessment/application/create',
    service_second_evaluate_person:
      'https://banco-co-onboarding-test.fif.tech/onboarding/assessment/evaluate/verify/second',
    service_more_job_info:
      'https://banco-co-onboarding-test.fif.tech/onboarding/assessment/evaluate/verify/more-job-info',
    service_content_ful:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/contentful/v1/products',
    service_external_authenticate_generate:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/external/generate',
    service_verify_first_factor:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/first-factor/validation',
    service_verify_second_factor_generation:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/second-factor/otp/generation',
    service_verify_second_factor_validation:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/second-factor/otp/validation',
    service_front_log:
      'https://banco-co-onboarding-test.fif.tech/onboarding/general/front-log/v1/',
    service_external_authenticate_validate:
      'https://banco-co-onboarding-test.fif.tech/onboarding/cross/authentication-ingress/assessment/external/validate',
    service_linkage_occupational_data:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/job-info', // job info
    service_linkage_personal_data:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/personal-data',
    service_linkage_pep_fatca:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/pep-fatca-info',
    service_linkage_payment_date:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/payment-date',
    service_linkage_referee_data:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/referee-data',
    service_linkage_pac_info:
    'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/pac-info',
    service_client_create:
      'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/client/v1/create-client',
    service_linkage_document_upload: 'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/document/v1/upload-id',
    service_linkage_document_save: 'https://banco-co-onboarding-test.fif.tech/onboarding/linkage/document/v1/save',
  };
}
