import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {environment} from '@environment/environment';
// tslint:disable-next-line:max-line-length
import {
    CONFIG_APP,
    CONFIG_CIPHER,
    CONFIG_COMMON,
    CONFIG_LOGGER,
    CONFIG_TOKEN,
    GlobalErrorHandlerService,
    LoggerService
} from 'cob-infrastructure';
import {ContentfulService} from './services/contentful/contentful.service';
import {GlobalAuthGuard} from './services/global-guard/auth-guard';
import { ApmService } from './services/logging/apm.service';
import { ScriptLoaderService } from './services/logging/script-loader.service';
import { LoggingService } from './services/logging/logging.interface';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { SecureServiceCallInterceptor } from './interceptors/secure-service-call.interceptor';


@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    providers:
        [
            {provide: CONFIG_TOKEN, useValue: environment.token},
            {provide: CONFIG_APP, useValue: environment.app},
            {provide: CONFIG_CIPHER, useValue: environment.cipher},
            {provide: CONFIG_COMMON, useValue: environment.common},
            {provide: CONFIG_LOGGER, useValue: {endpoint: environment.api.service_front_log}},
            {
                provide: LoggingService, useClass: ApmService
            },
            {
                provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true
            },
            {
                provide: HTTP_INTERCEPTORS, useClass: SecureServiceCallInterceptor, multi: true
            },

        ],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                GlobalAuthGuard,
                LoggerService,
                GlobalErrorHandlerService,
                ContentfulService,
                ApmService,
                ScriptLoaderService
            ],
        };
    }
}
