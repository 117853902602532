import {Injectable} from '@angular/core';
import {SessionKeys} from '@shared/domain/session-keys';
import {GeneralConfigService} from '@shared/services/general-config-service';
import {InvokerService, SessionStorageHandler} from 'cob-infrastructure';

@Injectable()
export class ContentfulService {
    intent = 3;

    constructor(
        private service: InvokerService,
        private generalConfigService: GeneralConfigService,
        private sessionStorageHandler: SessionStorageHandler
    ) {
    }

    // En caso de no poder conectar con contentfull, es preferible usar contenido desactualizado. Evaluar y quizá cambiar.
    // tslint:disable-next-line
    private fakeResponse = [{
        'productName': 'TC',
        'productBenefits': [{
            'code': 1,
            'value': '<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas'
        }, {
            'code': 2,
            'value': 'Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter'
        }, {'code': 3, 'value': '<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard'}],
        'productCost': [],
        'productTitle': 'Tarjeta de Crédito CMR',
        'productShorttitle': 'Tarjeta de Crédito CMR'
    }, {
        'productName': 'MASTERCARD',
        'productBenefits': [{
            'code': 1,
            'value': '<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas'
        }, {
            'code': 2,
            'value': 'Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter'
        }, {'code': 3, 'value': '<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard'}],
        'productCost': [{'code': 'CD', 'title': 'Cupo disponible', 'value': '0'}, {
            'code': 'CM',
            'title': 'Cuota de manejo',
            'value': '$15.300 al mes'
        }, {'title': 'Pagos en línea', 'value': 'Costo $0 <div>a través de PSE</div>'}],
        'productTitle': 'Tarjeta de Crédito CMR Banco Falabella',
        'productShorttitle': 'Tarjeta de Crédito'
    }, {
        'productName': 'PAC',
        'productBenefits': [{'code': 4, 'value': 'Tasas de interés con <strong>Alta rentabilidad</strong>'}, {
            'code': 5,
            'value': 'Tu dinero <strong>100% disponible</strong> cuando lo necesites. Retiros ilimitados'
        }, {
            'code': 6,
            'value': '<strong>Costo $0</strong> Cuota de manejo, abonos en línea y desde otros bancos o puntos baloto'
        }],
        'productCost': [{'code': 'CM', 'title': 'Cuota de manejo', 'value': 'Costo $0'}, {
            'title': 'Retiro del dinero',
            'value': '100% disponible <div>cuando lo necesites</div>'
        }, {'title': 'Tasas de interés', 'value': 'Preferenciales'}, {
            'title': 'Abonos en línea',
            'value': 'Costo $0 <div>a través de PSE</div>'
        }],
        'productTitle': 'Cuenta de Ahorros PAC',
        'productShorttitle': 'Cuenta de Ahorros PAC'
    }, {
        'productName': 'CERRADA',
        'productBenefits': [{
            'code': 1,
            'value': '<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas'
        }, {
            'code': 2,
            'value': 'Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter'
        }, {'code': 3, 'value': '<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard'}],
        'productCost': [{'code': 'CD', 'title': 'Cupo disponible', 'value': '0'}, {
            'code': 'CM',
            'title': 'Cuota de manejo',
            'value': '$15.300 al mes'
        }, {'title': 'Abonos en línea', 'value': 'Costo $0 a través de PSE'}, {
            'title': 'Retiros en cajeros propios',
            'value': 'Costo $0'
        }, {'title': 'Retiros en cajeros Servibanca', 'value': 'Costo $1.600'}],
        'productTitle': 'Tarjeta de Crédito CMR',
        'productShorttitle': 'Tarjeta de Crédito'
    }, {
        'productName': 'BLACK',
        'productBenefits': [{
            'code': 1,
            'value': '<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas'
        }, {
            'code': 2,
            'value': 'Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter'
        }, {'code': 3, 'value': '<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard'}],
        'productCost': [{'code': 'CD', 'title': 'Cupo disponible', 'value': '0'}, {
            'code': 'CM',
            'title': 'Cuota de manejo',
            'value': '$15.300 al mes'
        }, {'title': 'Abonos en línea', 'value': 'Costo $0 a través de PSE'}, {
            'title': 'Retiros en cajeros propios',
            'value': 'Costo $0'
        }, {'title': 'Retiros en cajeros Servibanca', 'value': 'Costo $1.600'}],
        'productTitle': 'Tarjeta de Crédito Black'
    }, {
        'productName': 'CA',
        'productBenefits': [{'code': 7, 'value': '<strong>Costo $0</strong> en cuota de manejo'}, {
            'code': 5,
            'value': '<strong>Retiros gratis</strong> e ilimitados en cajeros de cualquier banco a nivel nacional'
        }, {
            'code': 9,
            'value': '<strong>Costo $0</strong> en transferencias electrónicas a cuentas de cualquier banco'
        }, {'code': 8, 'value': '<strong>Costo $0</strong> en retiros y abonos en corresponsales VIA Baloto'}],
        'productCost': [{'code': 'CM', 'title': 'Cuota de manejo', 'value': 'Costo $0'}, {
            'title': 'Abonos en línea',
            'value': 'Costo $0 <div>a través de PSE</div>'
        }, {
            'title': 'Retiros en cajeros <div>propios</div>',
            'value': 'Costo $0'
        }, {'title': 'Retiros en cajeros <div>Servibanca</div>', 'value': '$1.600'}],
        'productTitle': 'Cuenta de Ahorro Costo $0',
        'productShorttitle': 'Cuenta de Ahorro',
        'productDisclaimer': '<strong>Condiciones:</strong> Para disfrutar de estos beneficios debes tener ingresos mensuales en esta cuenta iguales o superiores a $750.000.'
    }]; // eslint-disable-line

    public async sendToContent(): Promise<any> {
        const dummyValue = {};
        try {
            if (this.intent > 0) {
                this.intent = this.intent - 1;
                const response = await this.service.post(
                    dummyValue,
                    this.generalConfigService.getApiUrl('service_content_ful'),
                    {class: 'ContentfulService', method: 'sendToContent'}
                );
                this.saveContentful(response);
                return Promise.resolve(response);
            } else {
                this.saveContentful(this.fakeResponse);
                return Promise.resolve(this.fakeResponse);
            }
        } catch (e) {
            this.saveContentful(this.fakeResponse);
            return Promise.resolve(this.fakeResponse);
        }
    }

    getContent() {
        return JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.CONTENT));
    }

    saveContentful(responseContent) {
        const contenido = {content: responseContent};
        this.sessionStorageHandler.setValue(SessionKeys.CONTENT, JSON.stringify(contenido));
    }
}
