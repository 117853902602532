import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {

    @Input()
    public content: string;

    @Input()
    public title: string;

    @Output()
    public modalDisplay: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public hidden = true;

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.hidden = !this.hidden;
        this.modalDisplay.emit(this.hidden)
    }
}
