import {animate, state, style, transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RoutesPaths} from '@shared/domain/routes-paths';
import {AuthService, SessionService} from 'cob-infrastructure';

@Component({
    selector: 'app-login-avatar',
    templateUrl: './login-avatar.component.html',
    styleUrls: ['./login-avatar.component.scss'],
    animations: [
        trigger('changeState', [
            state(
                'close',
                style({
                    width: '0vw',
                    backgroundColor: 'transparent',
                })
            ),
            state(
                'open',
                style({
                    width: '70vw',
                    backgroundColor: 'white',
                    zIndex: 1,
                })
            ),
            transition('*=>open', animate('0.5s')),
            transition('*=>close', animate('0.3s')),
            transition(':leave', []),
        ]),
    ],
})
export class LoginAvatarComponent implements OnInit {
    toogle = 'close';
    hideAvatar = true;
    username = 'nologin';
    blurredScreenWanted = false;
    subcribeSessionService = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private sessionService: SessionService,
        private location: Location
    ) {
    }

    ngOnInit() {
        this.listenerRouter();
    }

    public listenerRouter() {
        if (this.subcribeSessionService === null) {
            this.subcribeSessionService = this.router.events.subscribe((val) => {
                const loginPath = `/${RoutesPaths.SALES_AGENT_LOGIN}`;
                const usernameSessionStorage = this.sessionService.getUsername();
                if (this.location.path() !== loginPath && this.authService.isLoggedIn()
                    && usernameSessionStorage !== 'anonymous') {
                    this.showAvatar(usernameSessionStorage);
                } else {
                    this.hideAvatar = true;
                }
            });
        }
    }

    public showAvatar(username) {
        this.hideAvatar = false;
        if (username !== null) {
            this.username = username.split('@')[0];
        }
    }

    public changeState() {
        this.toogle = this.toogle === 'open' ? 'close' : 'open';
        this.blurredScreenWanted = !this.blurredScreenWanted;
    }

    public closeSession() {
        this.toogle = 'close';
        this.blurredScreenWanted = false;
        this.authService.logout()
            .then(() => this.onRevokeCompleted())
            .catch(() => this.onRevokeCompleted());
    }

    public goToHome() {
        this.toogle = 'close';
        this.blurredScreenWanted = false;
        this.router.navigate(['']);
    }

    private onRevokeCompleted() {
        return this.router.navigate([RoutesPaths.SALES_AGENT_LOGIN]);
    }
}
