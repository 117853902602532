import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private DEFAULT_OPTIONS: object = { async: true };

  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  public load = (scriptSource: string, options = this.DEFAULT_OPTIONS): Promise<void> => new Promise((resolve, rejected) => {
    if (this.isLoaded(scriptSource)) {
      return resolve();
    }
    const scriptTag = this.document.createElement('script');
    scriptTag.src = scriptSource;
    scriptTag.type = 'text/javascript';
    Object.keys(options).forEach(key => scriptTag[key] = options[key]);
    this.document.body.appendChild(scriptTag);

    return resolve();
  })

  private isLoaded = (scriptSource: string): boolean => (this.getScriptsLoaded().find(script => script === scriptSource)) ? true : false;

  private getScriptsLoaded = (): Array<string> => Array.from(this.document.getElementsByTagName('script'))
    .filter(this.isValidScriptTag)
    .map((scriptTag: HTMLScriptElement): string => scriptTag.src);

  private isValidScriptTag = (scriptTag: HTMLScriptElement): boolean => scriptTag.type === 'text/javascript' && scriptTag.src !== '';
}
