import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/cmr-cl/personal-data/personal-data.module').then(m => m.PersonalDataModule),
        pathMatch: 'full'
    },
    {
        path: 'deuda',
        loadChildren: () => import('./features/cmr-cl/debt-pay/debt-pay.module').then(m => m.DebtPayModule)
    },
    {
        path: 'voucher',
        loadChildren: () => import('./features/cmr-cl/voucher-pay/voucher-pay.module').then(m => m.VoucherPayModule)
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
